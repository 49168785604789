<template>
    <v-dialog persistent v-model="editDialog" max-width="500">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" :disabled="selected.length != 1" text block color="secondary" class="justify-start">
                <v-icon left>mdi-table-edit</v-icon>
                edit description
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <div class="d-flex align-center title secondary--text">
                    <v-icon left>mdi-table-edit</v-icon>
                    <span class="text-uppercase">{{ objectType }} edit description</span>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-1">
                <span class="font-weight-bold">Old {{ objectType }} description:</span>
                <p>{{ oldName }}</p>
                <span class="font-weight-bold">New {{ objectType }} description:</span>
                <v-text-field v-model="newObjectName" outlined>
                    <template v-slot:label>Provide a new {{ objectType }} description</template>
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="editing" text color="secondary" class="mr-1" @click="editDialog = false">Cancel</v-btn>
                <v-btn :loading="editing" text color="error" @click="edit">confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { enumsData } from '@/mixins/enums'

export default {
    name: 'EditDescriptionButton',
    mixins: [enumsData],
    data: function () {
        return {
            editDialog: false,
            newObjectName: '',
            successMessage: '',
            editing: false
        }
    },
    props: {
        selected: Array,
        objectType: String,
        dispatchFunction: Function
    },
    computed: {
        oldName: function () {
            if (this.$props.selected.length && this.$props.selected[0].description) {
                return this.$props.selected[0].description
            } else {
                return '-'
            }
        }
    },
    methods: {
        edit() {
            this.editing = true
            const patchBody = {
                description: this.newObjectName
            }
            let apiURL
            if (this.$props.objectType === this.nuvolosObjectTypes.FILE) {
                this.successMessage = 'File description changed successfully!'
                apiURL = this.$props.objectType + 's/' + this.$props.selected[0].fid
            } else if (this.$props.objectType === this.nuvolosObjectTypes.TABLE) {
                this.successMessage = 'Table description changed successfully!'
                apiURL = this.$props.objectType + 's/' + this.$props.selected[0].tid
            } else if (this.$props.objectType === this.nuvolosObjectTypes.COLUMN) {
                this.successMessage = 'Column description changed successfully!'
                apiURL = this.$props.objectType + 's/' + this.$props.selected[0].cid
            }
            this.$axios
                .patch(apiURL, patchBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: this.successMessage,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.dispatchFunction()
                })
                .catch(error => {
                    console.log(error)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to edit the ${this.$props.objectType} description`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.editDialog = false
                    this.editing = false
                })
        }
    },
    mounted() {
        if (this.oldName !== '-') {
            this.newObjectName = this.oldName
        }
    }
}
</script>
